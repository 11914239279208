.content{
  color: white;
}

.photo-project{
  width:100%;
  height: 100%;
  object-fit: cover;
}

.photo-project-uShare{
  height: 85%;
  width: auto;
  display:block;
}


.photo-project-chess{
  width:100%;
  height: 100%;
}

.photo-project-MatchPoint{
  height: 85%;
  width: auto;
  display:block;
}

.photo-project-sim{
  width:100%;
  height: 100%;
}

.container-photo-project{
  position: relative;
  width: 100%;
  height: 100%;
}

.container-project{
  width: 30%;
  float: left;
  margin-right: 3%;
  margin-top: 1%;
  height: 320px;
}

.text{
  width: 100%;
  float: left;
  margin-top: 2%;
}

.image-project{
  width: 100%;
  max-width: 100%;
  float: left;
  height: 200px;
  background-color: #C0C0C0;
  margin-right: 3%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-projects{
  width: 100%;
  margin-top: 2%;
  margin-bottom: 6%;
  overflow:auto;
}

.icon-project {
  white-space: nowrap;
  color: white;
  font-size: 20px;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: .5s ease;
}

.container-project:hover .overlay {
  width: 100%;
}

.title-project{
  float: left;
  width: 50%;
  color: white;
  font-size: 20px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
}

.title-project-tfg{
  float: left;
  width: 80%;
  color: white;
  font-size: 20px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
}

.title-project-explanation{
  float: left;
  width: 100%;
  color: #a4acc4;
  font-size: 16px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
}

.language-code-android-studio{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #8FBC8F;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
}

.language-code-react-js{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #008B8B;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
}

.language-code-java{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #FFA500;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
}

.language-code-cplusplus{
  float: left;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #6495ED;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
  display: inline-block;
}

.language-code-mesa{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #ADD8E6;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
}

.language-code-flexsim{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #C0C0C0;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
}

.language-code-simpy{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #7FFFD4;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
}

.path-finding{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #DC143C;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
  display: inline-block;
  display:table-row;
}

.language-code-react-native{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: green;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
}

.language-code-react-python{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #ffff40;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
}

.link-title:hover .title-project{
  color: #4169E1;
}

.link-title:hover .title-project-tfg{
  color: #4169E1;
}

.container-code{
  width: 45%;
  float: right;
  margin-top: 2%;
  margin-left: 5%;
  display: table;
}

@media (min-width: 1200px) {
  .container-projects .container-project{
    height: 390px;
    margin-left: 2%;
    margin-right: 0%;
  }
  .content .container-projects{
    margin-top: 5%;
  }
}
@media (min-width: 1000px) and (max-width: 1200px){
  .container-projects .container-project{
    height: 390px;
    margin-left: 3%;
    margin-right: 0%;
  }
  .content .container-projects{
    margin-top: 5%;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  .container-projects .container-project{
    width: 45%;
    margin-left: 2.5%;
    margin-right: 1%;
    height: 380px;
  }
  .content .container-projects{
    margin-top: 10%;
  }
}
@media (min-width: 620px) and (max-width: 800px) {
  .container-project .image-project{
    width: 100%;
  }
  .container-projects .container-project{
    width: 45%;
    margin-left: 2.5%;
    margin-right: 1%;
    margin-bottom: 5%;
    height: 400px;
    margin-top: 0%;
  }
  .container-code .path-finding{
    float: left;
    margin-left: 5%;
  }
  .text .title-project{
    width: 100%;
  }
  .text .title-project-tfg{
    width: 100%;
  }
  .text .container-code{
    width: 100%;
  }
  .text .language-code-java{
    float: left;
  }
  .text .language-code-android-studio{
    float: left;
  }
  .text .language-code-flexsim{
    float: left;
  }
  .text .language-code-simpy{
    float: left;
  }
  .text .language-code-mesa{
    float: left;
  }
  .text .language-code-react-native{
    float: left;
  }
  .text .language-code-react-python{
    float: left;
  }
  .text .language-code-react-js{
    float: left;
  }
  .content .container-projects{
    margin-top: 15%;
  }
}
@media (max-width: 620px) {
  .container-project .image-project{
    width: 100%;
  }
  .container-projects .container-project{
    width: 90%;
    margin-left: 3.5%;
    margin-bottom: 10%;
    height: auto;
    margin-top: 0%;
  }
  .content .container-projects{
    margin-top: 15%;
    margin-bottom: 10%;
  }
}
