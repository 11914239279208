.side-drawer{
  height: 100%;
  background: white;
  box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
  position: fixed;
  top:0;
  left: 0;
  width: 30%;
  //max-width: 300px;
  max-width: 300px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open{
  transform: translateX(0);
}

.side-drawer ul{
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.side-drawer a:hover.
.side-drawer a:active{
  color: #fa923f;
}

.side-drawer li{
  margin: 0.5rem 0;
  text-align: center;
}

.side-drawer a{
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.a{
  box-sizing: border-box;
}

.container-photo{
  width: 100%;
  height: auto;
}

.separator{
  height: 20px;
}
.line{
  height: 0.5px;
  color: white;
  background-color: white;
}

.circular--landscape{
  width: 230px;
  height: 230px;
  overflow: hidden;
  border-radius: 50%;
  display: block;
  margin: auto;
  margin-bottom: 2px;
}


.container--profile{
  height: 80%;
}
.photo-profile{
  display: inline;
  margin: 0 auto;
  margin-left: -10%;
  margin-top: -5%;
  width:  110%;
}

.option{
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
}

.options{
  padding: 0;
  margin:0;
}

.option:hover{
  background-color: #4C4C4C;
}

.side-drawer a:hover{
  color: #C8C8C8;
}

.container-options{
  margin-top: 10%;
}

@media (max-width: 1000px) {
    .circular--landscape {
        width: 200px;
        height: 200px;
    }
}

@media (max-width: 800px) {
    .circular--landscape {
        width: 120px;
        height: 120px;
    }
    .side-drawer a{
      font-size: 0.9rem;
    }
}
@media (min-width: 300px) and (max-width: 600px) {
    .circular--landscape {
        width: 120px;
        height: 120px;
    }
    .side-drawer a{
      font-size: 1rem;
    }
    .side-drawer{
      max-width: none;
      width: 40%;
    }
}
