.container-home{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;

  font-size: 28px;
  font-family: sans-serif;
}

.container-name{
  color:#037fff;
  width: 100%;
}

.container-hi{
  color:white;
  width: 100%;
  float: center;
}

.principal-text{
  width: 100%;
  float: center;
}

.container-description{
  color: #a4acc4;
  font-size: 18px;
  font-weight: lighter;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}

.social-icon{
  float: center;
  margin-right: 10px;
}

.social-icon-right{
  float: center;
}
.container-icons{
  width: 100%;
}


@media (max-width: 580px) {
  .container-hi{
    width: 90%;
    margin-left: 5%;
    float: center;
  }
}
