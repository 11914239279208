.container-general{
  width: 96%;
  margin-left: 4%;
}

.content{
  margin-top: 10%;
  color: white;
}

.title-section{
  color: white;
  font-size: 40px;
  font-family: Nunito,sans-serif;
  margin-bottom: 10px;
}

.title-section-space{
  color: white;
  font-size: 40px;
  font-family: Nunito,sans-serif;
  margin-bottom: 10px;
  padding-top: 35%;
}

.separator-blue{
  background-color: #037fff;
  width: 75px;
  height: 5px;
  float: left;
  border: none;
  margin-left: 0;
  border-radius: 5px;
}

.photo-about{
  width: 500px;
  height: auto;
  margin-top: 20px;
  float: left;
  border: none;
}

.container-about{
  width: 100%;
  float: left;
  margin-top: 3%;
  margin-bottom: 5%;
}

.container-myName{
  float: left;
  font-size: 1.91rem;
  margin-left: 2%;
  margin-top: 1.5%;
  width: 98%;
  margin-bottom: 0;
}

.container-text{
  width: 96%;
  margin-left: 2%;
  float: left;
  font-size: 18px;
  line-height: 30px;
  margin-top: 20px;
  color: #a4acc4;
}

.question{
  font-weight: bold;
  font-size: 18px;
  width: 30%;
  float: left;
}

.list-names{
  margin-left: 2%;
  float: left;
  margin-top: 10px;
  width: 98%;
  font-size: 18px;
  color: #a4acc4;
}

.list-names-first{
  margin-left: 2%;
  float: left;
  margin-top: 30px;
  width: 98%;
  font-size: 1.2rem;
  color: #a4acc4;
  font-size: 18px;
}

.button-downloadCV{
  background-color: #037fff;
  margin-left: 2%;
  margin-top: 3%;
  border: none;
  color: white;;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button-downloadCV:hover{
  color: #a4acc4;
  cursor: pointer;
}

.answer{
  width: 50%;
  float: left;
}

.container-information-about{
  width: 45%;
  margin-top: 2%;
  float: left;
  margin-left: 1%;
}

.container-services{
  width: 97%;
  padding-top: 5%;
  overflow: hidden;
  margin-bottom: 30px;
  margin-left: 3%;
}

.photo-icon{
  width: 100px;
  height: auto;
  margin-top: 20px;
  float: left;
  max-width: 30%;
  border: none;
  margin-left: 9%;
}

.container-service{
  width: 30%;
  height: auto;
  float: left;
  background-color: #000020;
  margin-right: 3%;
  border: 1px solid #5f5faf;
  padding-bottom: 3%;
}

.line-blue{
  height: 6px;
  width: 100%;
  background-color: #4169E1;
}

.container-service:hover .line-blue{
  background-color: #037fff;
}

.title-service{
  float: left;
  margin-top: 20px;
  width: 91%;
  margin-left: 9%;
  font-size: 23px;
  color: white;
}

.title-service-explanation{
  float: left;
  margin-top: 20px;
  width: 91%;
  margin-left: 9%;
  font-size: 18px;
  color: grey;
}


@media (min-width: 1170px){
  .container-about .container-information-about{
    width: 40%;
    margin-top: 2%;
    float: left;
    margin-left: 1%;
  }
  .content .container-services{
    margin-bottom: 8%;
  }
  .container-general .title-section-space{
    margin-left: 3%;
  }
  .container-about .photo-about{
    margin-left: 3%;
  }
}

@media (min-width: 1000px) and (max-width: 1170px) {
  .container-general {
      width: 90%;
  }
  .content .container-services{
    margin-bottom: 10%;
  }
  .container-information-about .button-downloadCV{
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .title-section{
    margin-left: 3%;
  }
  .title-section-space{
    margin-left: 1%;
  }
  .container-general .separator-blue{
    margin-left: 3%;
  }
  .container-services{
    width: 90%;
    margin-left: 3%;
    margin-bottom: 5%;
  }
  .container-about .photo-about{
    margin-left: 3%;
  }
  .container-about .container-information-about{
    width: 90%;
    margin-bottom: 4%;
  }
  .container-services .container-service{
    width: 42%;
    margin-bottom: 5%;
    float: left;
    height: 200px;
  }
  .container-general .title-section-space{
    margin-left: 3%;
  }
}

@media (min-width: 700px) and (max-width: 1000px){
  .container-services .container-service{
    width: 42%;
    margin-bottom: 5%;
    float: left;
    height: 200px;
  }
  .container-service .title-service{
    font-size: 20px;
  }
  .container-service .title-service-explanation{
    font-size: 17px;
    width: 80%;
  }
  .title-section{
    margin-left: 3%;
  }
  .title-section-space{
    margin-left: 3%;
  }
  .container-general .separator-blue{
    margin-left: 3%;
  }
  .container-information-about .button-downloadCV{
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .container-about .photo-about{
    width: 85%;
    max-width: 500px;
    margin-left: 3%;
  }
  .container-general {
      margin-left: 11%;
      width: 89%;
  }
  .container-about .container-information-about{
    width: 90%;
  }
  .content .container-services{
    margin-bottom: 10%;
  }
}


@media (min-width: 580px) and (max-width: 700px) {
  .container-services .container-service{
    width: 90%;
    margin-left: 3%;
    margin-bottom: 5%;
    height: auto;
  }
  .container-general {
     margin-left: 11%;
     width: 89%;
 }
 .container-about .container-information-about{
   width: 90%;
   margin-bottom: 4%;
 }
 .answer{
      margin-left: 20%;
  }
  .container-information-about .button-downloadCV{
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .container-about .photo-about{
    width: 85%;
    margin-left: 3%;
  }
  .title-section{
    margin-left: 3%;
  }
  .title-section-space{
    margin-left: 3%;
  }
  .container-general .separator-blue{
    margin-left: 3%;
  }
  .content .container-services{
    margin-bottom: 20%;
  }
  .container-service .title-service{
    width: 50%;
    margin-top: 10%;
  }
  .container-service .title-service-explanation{
    width: 50%;
  }
}

@media (max-width: 580px) {
  .container-services .container-service{
    width: 95%;
    margin-bottom: 5%;
  }
  .container-general {
     margin-left: 11%;
     width: 89%;
 }
 .container-about .container-information-about{
   width: 90%;
   margin-bottom: 4%;
 }
 .answer{
      margin-left: 20%;
  }
  .container-information-about .button-downloadCV{
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .container-about .photo-about{
    width: 85%;
    margin-left: 3%;
  }
  .title-section{
    margin-left: 3%;
  }
  .title-section-space{
    margin-left: 3%;
  }
  .container-general .separator-blue{
    margin-left: 3%;
  }
  .content .container-services{
    margin-bottom: 20%;
  }
}
