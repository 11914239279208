.toolbar{
  position: fixed;
  top:0;
  left:0;
  width: auto;
  background-color: #037fff;
  height: 56px;
  cursor: pointer;
}

.toolbar__navigation{
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0;
}

.toolbar__photo{
  margin-left: 1rem;
}

.toolbar__photo a{
  color:white;
  text-decoration: none;
  font-size: 1.5rem;
}

.spacer{
  flex:1;
}

.toolbar__navigation-items ul{
  list-style: none;
  margin:0;
  padding:0;
  display:flex;

}

.toolbar__navigation-items li{
  padding: 0 0.5rem;
  display: none;
}

.toolbar__navigation-items a{
  color: white;
  text-decoration: none;
}

.toolbar__navigation-items a:hover,
.toolbar__navigation-items a:active{
  color: #fa923f;
  text-decoration: none;
}

@media (max-width: 768px) {
  .toolbar__navigation-items{
    display:none;
  }
}

@media (min-width: 769px){
  .toolbar__toogle-button{
    /*display: none; A lo mejor en un futuro*/

  }
}
