body {
  margin: 0;
  font-family: -apple-system, 'Sans Serif', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toggle-button{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 36px;
  background: transparent;
  border: none;
  margin-left: 6px;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
}

.toggle-button:focus{
    outline: none;
}

.toggle-button__line{
  width: 30px;
  height: 2px;
  background: white;
}

.toolbar{
  position: fixed;
  top:0;
  left:0;
  width: auto;
  background-color: #037fff;
  height: 56px;
  cursor: pointer;
}

.toolbar__navigation{
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0;
}

.toolbar__photo{
  margin-left: 1rem;
}

.toolbar__photo a{
  color:white;
  text-decoration: none;
  font-size: 1.5rem;
}

.spacer{
  flex:1 1;
}

.toolbar__navigation-items ul{
  list-style: none;
  margin:0;
  padding:0;
  display:flex;

}

.toolbar__navigation-items li{
  padding: 0 0.5rem;
  display: none;
}

.toolbar__navigation-items a{
  color: white;
  text-decoration: none;
}

.toolbar__navigation-items a:hover,
.toolbar__navigation-items a:active{
  color: #fa923f;
  text-decoration: none;
}

@media (max-width: 768px) {
  .toolbar__navigation-items{
    display:none;
  }
}

@media (min-width: 769px){
  .toolbar__toogle-button{
    /*display: none; A lo mejor en un futuro*/

  }
}

.side-drawer{
  height: 100%;
  background: white;
  box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
  position: fixed;
  top:0;
  left: 0;
  width: 30%;
  //max-width: 300px;
  max-width: 300px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open{
  transform: translateX(0);
}

.side-drawer ul{
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.side-drawer a:hover.
.side-drawer a:active{
  color: #fa923f;
}

.side-drawer li{
  margin: 0.5rem 0;
  text-align: center;
}

.side-drawer a{
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.a{
  box-sizing: border-box;
}

.container-photo{
  width: 100%;
  height: auto;
}

.separator{
  height: 20px;
}
.line{
  height: 0.5px;
  color: white;
  background-color: white;
}

.circular--landscape{
  width: 230px;
  height: 230px;
  overflow: hidden;
  border-radius: 50%;
  display: block;
  margin: auto;
  margin-bottom: 2px;
}


.container--profile{
  height: 80%;
}
.photo-profile{
  display: inline;
  margin: 0 auto;
  margin-left: -10%;
  margin-top: -5%;
  width:  110%;
}

.option{
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
}

.options{
  padding: 0;
  margin:0;
}

.option:hover{
  background-color: #4C4C4C;
}

.side-drawer a:hover{
  color: #C8C8C8;
}

.container-options{
  margin-top: 10%;
}

@media (max-width: 1000px) {
    .circular--landscape {
        width: 200px;
        height: 200px;
    }
}

@media (max-width: 800px) {
    .circular--landscape {
        width: 120px;
        height: 120px;
    }
    .side-drawer a{
      font-size: 0.9rem;
    }
}
@media (min-width: 300px) and (max-width: 600px) {
    .circular--landscape {
        width: 120px;
        height: 120px;
    }
    .side-drawer a{
      font-size: 1rem;
    }
    .side-drawer{
      max-width: none;
      width: 40%;
    }
}

.backdrop{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  top:0;
  left: 0;
}

.container-home{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;

  font-size: 28px;
  font-family: sans-serif;
}

.container-name{
  color:#037fff;
  width: 100%;
}

.container-hi{
  color:white;
  width: 100%;
  float: center;
}

.principal-text{
  width: 100%;
  float: center;
}

.container-description{
  color: #a4acc4;
  font-size: 18px;
  font-weight: lighter;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}

.social-icon{
  float: center;
  margin-right: 10px;
}

.social-icon-right{
  float: center;
}
.container-icons{
  width: 100%;
}


@media (max-width: 580px) {
  .container-hi{
    width: 90%;
    margin-left: 5%;
    float: center;
  }
}

.container-general{
  width: 96%;
  margin-left: 4%;
}

.content{
  margin-top: 10%;
  color: white;
}

.title-section{
  color: white;
  font-size: 40px;
  font-family: Nunito,sans-serif;
  margin-bottom: 10px;
}

.title-section-space{
  color: white;
  font-size: 40px;
  font-family: Nunito,sans-serif;
  margin-bottom: 10px;
  padding-top: 35%;
}

.separator-blue{
  background-color: #037fff;
  width: 75px;
  height: 5px;
  float: left;
  border: none;
  margin-left: 0;
  border-radius: 5px;
}

.photo-about{
  width: 500px;
  height: auto;
  margin-top: 20px;
  float: left;
  border: none;
}

.container-about{
  width: 100%;
  float: left;
  margin-top: 3%;
  margin-bottom: 5%;
}

.container-myName{
  float: left;
  font-size: 1.91rem;
  margin-left: 2%;
  margin-top: 1.5%;
  width: 98%;
  margin-bottom: 0;
}

.container-text{
  width: 96%;
  margin-left: 2%;
  float: left;
  font-size: 18px;
  line-height: 30px;
  margin-top: 20px;
  color: #a4acc4;
}

.question{
  font-weight: bold;
  font-size: 18px;
  width: 30%;
  float: left;
}

.list-names{
  margin-left: 2%;
  float: left;
  margin-top: 10px;
  width: 98%;
  font-size: 18px;
  color: #a4acc4;
}

.list-names-first{
  margin-left: 2%;
  float: left;
  margin-top: 30px;
  width: 98%;
  font-size: 1.2rem;
  color: #a4acc4;
  font-size: 18px;
}

.button-downloadCV{
  background-color: #037fff;
  margin-left: 2%;
  margin-top: 3%;
  border: none;
  color: white;;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button-downloadCV:hover{
  color: #a4acc4;
  cursor: pointer;
}

.answer{
  width: 50%;
  float: left;
}

.container-information-about{
  width: 45%;
  margin-top: 2%;
  float: left;
  margin-left: 1%;
}

.container-services{
  width: 97%;
  padding-top: 5%;
  overflow: hidden;
  margin-bottom: 30px;
  margin-left: 3%;
}

.photo-icon{
  width: 100px;
  height: auto;
  margin-top: 20px;
  float: left;
  max-width: 30%;
  border: none;
  margin-left: 9%;
}

.container-service{
  width: 30%;
  height: auto;
  float: left;
  background-color: #000020;
  margin-right: 3%;
  border: 1px solid #5f5faf;
  padding-bottom: 3%;
}

.line-blue{
  height: 6px;
  width: 100%;
  background-color: #4169E1;
}

.container-service:hover .line-blue{
  background-color: #037fff;
}

.title-service{
  float: left;
  margin-top: 20px;
  width: 91%;
  margin-left: 9%;
  font-size: 23px;
  color: white;
}

.title-service-explanation{
  float: left;
  margin-top: 20px;
  width: 91%;
  margin-left: 9%;
  font-size: 18px;
  color: grey;
}


@media (min-width: 1170px){
  .container-about .container-information-about{
    width: 40%;
    margin-top: 2%;
    float: left;
    margin-left: 1%;
  }
  .content .container-services{
    margin-bottom: 8%;
  }
  .container-general .title-section-space{
    margin-left: 3%;
  }
  .container-about .photo-about{
    margin-left: 3%;
  }
}

@media (min-width: 1000px) and (max-width: 1170px) {
  .container-general {
      width: 90%;
  }
  .content .container-services{
    margin-bottom: 10%;
  }
  .container-information-about .button-downloadCV{
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .title-section{
    margin-left: 3%;
  }
  .title-section-space{
    margin-left: 1%;
  }
  .container-general .separator-blue{
    margin-left: 3%;
  }
  .container-services{
    width: 90%;
    margin-left: 3%;
    margin-bottom: 5%;
  }
  .container-about .photo-about{
    margin-left: 3%;
  }
  .container-about .container-information-about{
    width: 90%;
    margin-bottom: 4%;
  }
  .container-services .container-service{
    width: 42%;
    margin-bottom: 5%;
    float: left;
    height: 200px;
  }
  .container-general .title-section-space{
    margin-left: 3%;
  }
}

@media (min-width: 700px) and (max-width: 1000px){
  .container-services .container-service{
    width: 42%;
    margin-bottom: 5%;
    float: left;
    height: 200px;
  }
  .container-service .title-service{
    font-size: 20px;
  }
  .container-service .title-service-explanation{
    font-size: 17px;
    width: 80%;
  }
  .title-section{
    margin-left: 3%;
  }
  .title-section-space{
    margin-left: 3%;
  }
  .container-general .separator-blue{
    margin-left: 3%;
  }
  .container-information-about .button-downloadCV{
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .container-about .photo-about{
    width: 85%;
    max-width: 500px;
    margin-left: 3%;
  }
  .container-general {
      margin-left: 11%;
      width: 89%;
  }
  .container-about .container-information-about{
    width: 90%;
  }
  .content .container-services{
    margin-bottom: 10%;
  }
}


@media (min-width: 580px) and (max-width: 700px) {
  .container-services .container-service{
    width: 90%;
    margin-left: 3%;
    margin-bottom: 5%;
    height: auto;
  }
  .container-general {
     margin-left: 11%;
     width: 89%;
 }
 .container-about .container-information-about{
   width: 90%;
   margin-bottom: 4%;
 }
 .answer{
      margin-left: 20%;
  }
  .container-information-about .button-downloadCV{
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .container-about .photo-about{
    width: 85%;
    margin-left: 3%;
  }
  .title-section{
    margin-left: 3%;
  }
  .title-section-space{
    margin-left: 3%;
  }
  .container-general .separator-blue{
    margin-left: 3%;
  }
  .content .container-services{
    margin-bottom: 20%;
  }
  .container-service .title-service{
    width: 50%;
    margin-top: 10%;
  }
  .container-service .title-service-explanation{
    width: 50%;
  }
}

@media (max-width: 580px) {
  .container-services .container-service{
    width: 95%;
    margin-bottom: 5%;
  }
  .container-general {
     margin-left: 11%;
     width: 89%;
 }
 .container-about .container-information-about{
   width: 90%;
   margin-bottom: 4%;
 }
 .answer{
      margin-left: 20%;
  }
  .container-information-about .button-downloadCV{
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .container-about .photo-about{
    width: 85%;
    margin-left: 3%;
  }
  .title-section{
    margin-left: 3%;
  }
  .title-section-space{
    margin-left: 3%;
  }
  .container-general .separator-blue{
    margin-left: 3%;
  }
  .content .container-services{
    margin-bottom: 20%;
  }
}

.content{
  margin-top: 10%;
  color: white;
  font-family: Nunito,sans-serif;
}

.separator-blue{
  background-color: #037fff;
  width: 75px;
  height: 5px;
  float: left;
  border: none;
  margin: 0;
  border-radius: 5px;
}

.container-language{
  width: 40%;
  float: left;
  margin-top: 50px;
  margin-right: 10%;
}

.language-representation{
  width: 100%;
  margin-top: 10px;
}

.language{
  width: 100%;
  font-size: 25px;
  font-weight: lighter;
}

.progressBar{
  width: 85%;
  float: left;
  margin-top: 1.1%;
}

.percentage{
  font-family: Nunito,sans-serif;
  width: 10%;
  margin-right: 5%;
  float: left;
  font-size: 18px;
  color: #a4acc4;
}

.title-section-space-resume{
  color: white;
  font-size: 40px;
  font-family: Nunito,sans-serif;
  margin-bottom: 10px;
  margin-top: 80%;
}

.container-section{
  width: 100%;
  float: left;
}

.container-section-last{
  width: 100%;
  float: left;
  margin-bottom: 5%;
}

.subtitle-resume{
  color: white;
  font-size: 22px;
  font-family: Nunito,sans-serif;
  float: left;
  width: 60%;
  margin-top: 1.45%;
}

.title-resume-icon{
  margin-top: 5%;
  margin-bottom: 10px;
  width: 100%;
  float: left;
}

.icon-resume{
  width: 40px;
  height: auto;
  float: left;
  max-width: 30%;
  border: none;
  margin-right: 1%;
}

.container {
  width: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 2px solid grey;
  margin-top: 4%;
  margin-left: 2%;
  height: 400px;
}

.container div {
  background-color: #ffffff;
  font-weight: bold;
  border: 2px solid grey;
  margin-top: -20px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  margin-left: -63%;
  float: left;
}

.container-line-working-experience{
  width: 100%;
  display: flex;
}

.text-working-experience{
  width: 100%;
  float: left;
  margin-top: 4.75%;
}

.text-working-experience-first{
  width: 100%;
  float: left;
  margin-top: 2%;
}

.text-working-education-first{
  width: 100%;
  float: left;
  margin-top: 2%;
}

.text-working-experience-first-education{
  width: 100%;
  float: left;
  margin-top: 6.5%;
  margin-bottom: 2.3%;
  height: 180px;
}

.text-working-experience-first-education-last{
  width: 100%;
  float: left;
  margin-top: 6.5%;
  margin-bottom: 2.3%;
  height: 140px;
}

.text-date{
  width: 20%;
  float: left;
  margin-top: 0.5%;

}

.container-information-working-experience{
  width: 95%;
  display: flex;
  flex-direction: column;
  margin-top: 0.2%;

}

.text-subtitle-working-experience{
  color: #037fff;
  font-size: 22px;
  font-family: Nunito,sans-serif;
  float: left;
  width: 100%;
  margin-bottom: 1%;
}

.text-info-working-experience{
  color: white;
  font-size: 18px;
  font-family: Nunito,sans-serif;
  float: left;
  width: 100%;
  margin-bottom: 1%;
}

.text-info-explanation-working-experience{
  color: grey;
  font-size: 18px;
  font-family: Nunito,sans-serif;
  float: left;
  width: 100%;
  margin-bottom: 1%;
}

.text-info-explanation-working-experience-big{
  color: grey;
  font-size: 18px;
  font-family: Nunito,sans-serif;
  float: left;
  width: 100%;
  margin-bottom: 1%;
}

@media (min-width: 850px) and (max-width: 1150px) {
  .container-about .container-section{
    margin-bottom: 10%;
    margin-left: 5%;
    width: 95%;
  }
  .language-representation .percentage{
    margin-right: 10%;
  }
  .container-general .title-section-space-resume{
    padding-left: 3%;
    width: 97%;
  }
  .content .container-section{
    margin-left: 3%;
    margin-bottom: 0%;
    width: 97%;
  }
  .content .container-section-last{
    margin-left: 3%;
    width: 97%;
  }
  .title-resume-icon .icon-resume{
    margin-right: 4%;
  }
  .container-section .title-resume-icon{
    margin-bottom: 5%;
    margin-top: 10%;
  }
  .container-section-last .title-resume-icon{
    margin-bottom: 5%;
  }
  .container-line-working-experience .text-working-experience-first{
    margin-left: 5%;
    width: 95%;
    height: 197px;
    margin-bottom: 0%;
    margin-top: 0%;
  }
  .container-line-working-experience .text-working-education-first{
    margin-left: 5%;
    width: 95%;
    height: 197px;
    margin-bottom: 0%;
    margin-top: 1%;
  }
  .container-line-working-experience .text-working-experience-first-education{
    margin-left: 5%;
    width: 95%;
    height: 200px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .container-line-working-experience .text-working-experience-first-education-last{
    margin-left: 5%;
    width: 95%;
    height: 140px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .container-line-working-experience .text-working-experience{
    margin-left: 5%;
    width: 95%;
    height: 190px;
    margin-top: 1.0%;
  }
  .container-information-working-experience .text-info-working-experience{
    margin-bottom: 3%;
    font-size: 16px;
  }
  .container-line-working-experience .text-date{
    margin-right: 3%;
    width: 100%;
  }
  .container-information-working-experience .text-subtitle-working-experience{
    margin-bottom: 3%;
    font-size: 20px;
  }
  .container-information-working-experience .text-info-explanation-working-experience{
    margin-bottom: 3%;
    font-size: 16px;
  }
  .container-information-working-experience .text-info-explanation-working-experience-big{
    margin-bottom: 3%;
    font-size: 16px;
    width: 100%;
  }
  .container div{
    margin-left: -12px;
  }
  .content .container-about{
    margin-bottom: 10%;
  }
}
@media (min-width: 1150px) {
  .container-about .container-section{
    margin-bottom: 12%;
    margin-left: 5%;
    width: 95%;
  }
  .language-representation .percentage{
    margin-right: 10%;
  }
  .container-general .title-section-space-resume{
    padding-left: 3%;
    width: 97%;
  }
  .content .container-section{
    margin-left: 3%;
    width: 97%;
  }
  .content .container-section-last{
    margin-left: 3%;
    width: 97%;
  }
  .title-resume-icon .icon-resume{
    margin-right: 4%;
  }
  .container-section .title-resume-icon{
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .container-section-last .title-resume-icon{
    margin-bottom: 5%;
  }
  .container-line-working-experience .text-working-experience-first{
    margin-left: 5%;
    width: 95%;
    height: 197px;
    margin-bottom: 0%;
    margin-top: 0%;
  }
  .container-line-working-experience .text-working-education-first{
    margin-left: 5%;
    width: 95%;
    height: 197px;
    margin-bottom: 0%;
    margin-top: 1%;
  }
  .container-line-working-experience .text-working-experience-first-education{
    margin-left: 5%;
    width: 95%;
    height: 200px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .container-line-working-experience .text-working-experience-first-education-last{
    margin-left: 5%;
    width: 95%;
    height: 140px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .container-line-working-experience .text-working-experience{
    margin-left: 5%;
    width: 95%;
    height: 190px;
    margin-top: 1.0%;
  }
  .container-information-working-experience .text-info-working-experience{
    margin-bottom: 3%;
    font-size: 16px;
  }
  .container-line-working-experience .text-date{
    margin-right: 3%;
    width: 100%;
  }
  .container-information-working-experience .text-subtitle-working-experience{
    margin-bottom: 3%;
    font-size: 20px;
  }
  .container-information-working-experience .text-info-explanation-working-experience{
    margin-bottom: 3%;
    font-size: 16px;
  }
  .container-information-working-experience .text-info-explanation-working-experience-big{
    margin-bottom: 3%;
    font-size: 16px;
    width: 100%;
  }
  .container div{
    margin-left: -12px;
  }
  .content .separator-blue{
    margin-left: 3%;
  }
  .content .title-section{
    margin-left: 3%;
  }
}

@media (max-width: 850px){
  .container-section .container-language{
    width: 88%;
  }
  .container-about .container-section{
    margin-bottom: 20%;
  }
  .container-general .title-section-space-resume{
    padding-left: 3%;
    width: 97%;
  }
  .content .container-section{
    margin-left: 3%;
    margin-top: 0%;
    width: 97%;
  }
  .content .container-section-last{
    margin-left: 3%;
    width: 97%;
  }
  .title-resume-icon .icon-resume{
    margin-right: 4%;
  }
  .container-section .title-resume-icon{
    margin-bottom: 10%;
    margin-top: 10%;
  }
  .container-section-last .title-resume-icon{
    margin-bottom: 10%;
  }
  .container-line-working-experience .text-working-experience-first{
    margin-left: 5%;
    width: 95%;
    height: 197px;
    margin-bottom: 0%;
    margin-top: -2%;
  }
  .container-line-working-experience .text-working-education-first{
    margin-left: 5%;
    width: 95%;
    height: 197px;
    margin-bottom: 0%;
    margin-top: -2%;
  }
  .container-line-working-experience .text-working-experience-first-education{
    margin-left: 5%;
    width: 95%;
    height: 200px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .container-line-working-experience .text-working-experience-first-education-last{
    margin-left: 5%;
    width: 95%;
    height: 140px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .container-line-working-experience .text-working-experience{
    margin-left: 5%;
    width: 95%;
    height: 200px;
    margin-top: 0;
  }
  .container-information-working-experience .text-info-working-experience{
    margin-bottom: 3%;
    font-size: 16px;
  }
  .container-line-working-experience .text-date{
    margin-right: 3%;
    width: 100%;
  }
  .container-information-working-experience .text-subtitle-working-experience{
    margin-bottom: 3%;
    font-size: 20px;
  }
  .container-information-working-experience .text-info-explanation-working-experience{
    margin-bottom: 3%;
    font-size: 16px;
  }
  .container-information-working-experience .text-info-explanation-working-experience-big{
    margin-bottom: 3%;
    font-size: 85%;
    width: 100%;
  }
  .container div{
    margin-left: -12px;
  }
}

.content{
  color: white;
}

.photo-project{
  width:100%;
  height: 100%;
  object-fit: cover;
}

.photo-project-uShare{
  height: 85%;
  width: auto;
  display:block;
}


.photo-project-chess{
  width:100%;
  height: 100%;
}

.photo-project-MatchPoint{
  height: 85%;
  width: auto;
  display:block;
}

.photo-project-sim{
  width:100%;
  height: 100%;
}

.container-photo-project{
  position: relative;
  width: 100%;
  height: 100%;
}

.container-project{
  width: 30%;
  float: left;
  margin-right: 3%;
  margin-top: 1%;
  height: 320px;
}

.text{
  width: 100%;
  float: left;
  margin-top: 2%;
}

.image-project{
  width: 100%;
  max-width: 100%;
  float: left;
  height: 200px;
  background-color: #C0C0C0;
  margin-right: 3%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-projects{
  width: 100%;
  margin-top: 2%;
  margin-bottom: 6%;
  overflow:auto;
}

.icon-project {
  white-space: nowrap;
  color: white;
  font-size: 20px;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: .5s ease;
}

.container-project:hover .overlay {
  width: 100%;
}

.title-project{
  float: left;
  width: 50%;
  color: white;
  font-size: 20px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
}

.title-project-tfg{
  float: left;
  width: 80%;
  color: white;
  font-size: 20px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
}

.title-project-explanation{
  float: left;
  width: 100%;
  color: #a4acc4;
  font-size: 16px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
}

.language-code-android-studio{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #8FBC8F;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
}

.language-code-react-js{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #008B8B;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
}

.language-code-java{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #FFA500;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
}

.language-code-cplusplus{
  float: left;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #6495ED;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
  display: inline-block;
}

.language-code-mesa{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #ADD8E6;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
}

.language-code-flexsim{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #C0C0C0;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
}

.language-code-simpy{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #7FFFD4;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
}

.path-finding{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #DC143C;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
  display: inline-block;
  display:table-row;
}

.language-code-react-native{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: green;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
}

.language-code-react-python{
  float: right;
  width: auto;
  color: black;
  font-size: 15px;
  font-family: Nunito,sans-serif;
  margin-top: 3%;
  background-color: #ffff40;
  border-radius: 2px;
  text-align: center;
  padding: 0.5%;
}

.link-title:hover .title-project{
  color: #4169E1;
}

.link-title:hover .title-project-tfg{
  color: #4169E1;
}

.container-code{
  width: 45%;
  float: right;
  margin-top: 2%;
  margin-left: 5%;
  display: table;
}

@media (min-width: 1200px) {
  .container-projects .container-project{
    height: 390px;
    margin-left: 2%;
    margin-right: 0%;
  }
  .content .container-projects{
    margin-top: 5%;
  }
}
@media (min-width: 1000px) and (max-width: 1200px){
  .container-projects .container-project{
    height: 390px;
    margin-left: 3%;
    margin-right: 0%;
  }
  .content .container-projects{
    margin-top: 5%;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  .container-projects .container-project{
    width: 45%;
    margin-left: 2.5%;
    margin-right: 1%;
    height: 380px;
  }
  .content .container-projects{
    margin-top: 10%;
  }
}
@media (min-width: 620px) and (max-width: 800px) {
  .container-project .image-project{
    width: 100%;
  }
  .container-projects .container-project{
    width: 45%;
    margin-left: 2.5%;
    margin-right: 1%;
    margin-bottom: 5%;
    height: 400px;
    margin-top: 0%;
  }
  .container-code .path-finding{
    float: left;
    margin-left: 5%;
  }
  .text .title-project{
    width: 100%;
  }
  .text .title-project-tfg{
    width: 100%;
  }
  .text .container-code{
    width: 100%;
  }
  .text .language-code-java{
    float: left;
  }
  .text .language-code-android-studio{
    float: left;
  }
  .text .language-code-flexsim{
    float: left;
  }
  .text .language-code-simpy{
    float: left;
  }
  .text .language-code-mesa{
    float: left;
  }
  .text .language-code-react-native{
    float: left;
  }
  .text .language-code-react-python{
    float: left;
  }
  .text .language-code-react-js{
    float: left;
  }
  .content .container-projects{
    margin-top: 15%;
  }
}
@media (max-width: 620px) {
  .container-project .image-project{
    width: 100%;
  }
  .container-projects .container-project{
    width: 90%;
    margin-left: 3.5%;
    margin-bottom: 10%;
    height: auto;
    margin-top: 0%;
  }
  .content .container-projects{
    margin-top: 15%;
    margin-bottom: 10%;
  }
}

.content{
  margin-top: 10%;
  color: white;
}

.container-contact-form{
  width: 50%;
  float: left;
  margin-bottom: 10%;
}

.container-contact-options{
  float: left;
  width: 46%;
  margin-left: 4%;
}

.form-group-name{
  position: absolute;
  left: 15px;
  top: -11px;
  background: #10121b;
  transition: all .4s ease-out;
  pointer-events: none;
  padding: 0 10px;
  font-size: .94rem;
  width: auto;
}
.form-group-subject{
  position: absolute;
  left: 15px;
  top: -11px;
  background: #10121b;
  transition: all .4s ease-out;
  pointer-events: none;
  padding: 0 10px;
  font-size: .94rem;
  width: auto;
}
.form-group-email{
  position: absolute;
  left: 15px;
  top: -11px;
  background: #10121b;
  transition: all .4s ease-out;
  pointer-events: none;
  padding: 0 10px;
  font-size: .94rem;
  width: auto;
}
.form-group-message{
  position: absolute;
  left: 15px;
  top: -11px;
  background: #10121b;
  transition: all .4s ease-out;
  pointer-events: none;
  padding: 0 10px;
  font-size: .94rem;
  width: auto;
}

.form-group{
  margin-bottom: 3%;
  position: relative;
  width: 100%;
}

.container-contact{
  width: 100%;
  float: left;
  margin-top: 6%;
}

.form-control{
  background-color: transparent;
  border: 1px solid #037fff;
  font-family: Nunito,sans-serif;
  color: #a4acc4;
  height: 50px;
  padding: 0 15px;
  width: 100%;
  box-sizing: border-box;
}

.form-control-message{
  background-color: transparent;
  border: 1px solid #037fff;
  font-family: Nunito,sans-serif;
  color: #a4acc4;
  height: 100px;
  width: 100%;
  padding: 10px 15px;
  box-sizing: border-box;
}

.button-send-mail{
  background-color: #037fff;
  margin-top: 0%;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  float:left;
  margin-right: 5%;
}

.photo-icon-mail{
  width: 50px;
  height: auto;
  float: center;
  max-width: 100%;
  border: none;
  text-align: center;
  vertical-align: middle;
}

.container-contact-email{
  background-color: #191d2b;
  width: 90%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
}

.container-email-border{
  display: inline-block;
  height: 70px;
  width: 70px;
  flex: 0 0 70px;
  max-width: 70px;
  border: 1px solid #2e344e;
  text-align: center;
  line-height: 66px;
  margin-right: 20px;
  vertical-align: middle;
}

.container-email-info{
  width: auto;
  vertical-align: middle;
}

.container-email-title{
  font-family: Nunito,sans-serif;
  color: #fff;
  font-size: 1.14rem;
  line-height: 1.857rem;
  margin-bottom: 11px;
  margin-top: 2px;
}

.container-email-text{
  color: #a4acc4;
  transition: all .4s ease-out;
}

.container-alert-incorrect{
  background-color: #f63535;
  padding: 3%;
  float: left;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  float:left;
  width: 50%;
}

.container-alert-correct{
  background-color: #3d9f54;
  padding: 3%;
  float: left;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  float:left;
  width: 50%;
}

@media (min-width: 1200px){
  .container-contact-options .container-contact-email{
    width: 95%;
  }
  .container-contact-form .form-group{
    margin-bottom: 10%;
  }
  .container-contact-form .container-alert-incorrect{
    width: 40%;
  }
  .container-contact-form .container-alert-correct{
    width: 40%;
  }
}
@media (min-width: 980px) and (max-width: 1200px) {
  .container-email-info .container-email-text{
    font-size: 1.2vw;
  }
  .container-general .container-contact{
    margin-left: 3%;
    width: 97%;
  }
  .container-contact-options .container-contact-email{
    width: 95%;
  }
  .container-contact-form .form-group{
    margin-bottom: 10%;
  }
  .container-contact-form .container-alert-correct{
    width: 28%;
  }
  .container-contact-form .container-alert-incorrect{
    width: 28%;
  }
}
@media (max-width: 980px) {
  .container-contact .container-contact-form{
    width: 90%;
    margin-left: 4%;
    margin-top: 5%;
  }
  .container-contact .container-contact-options{
    width: 95%;
    margin-bottom: 20%;
  }
  .container-email-info .container-email-text{
    font-size: 13px;
  }
  .container-contact-options .container-contact-email{
    width: 95%;
  }
  .container-contact-form .form-group{
    margin-bottom: 10%;
  }
  .container-contact .button-send-mail{
    margin-bottom: 5%;
  }
}

@media (max-width: 700px) {
  .container-email-info .container-email-text{
    font-size: 12px;
  }
  .container-general .content{
    margin-top: 25%;
  }
}

.scroll-to-top{
  position: fixed;
  bottom: 12px;
  left: 50%;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: blue;
  color: white;
  z-index: 1;
}

.scroll-to-top .icon{
  margin-top: 10px;
  animation: scrollTop 0.5s alternate ease infinite;
}
@keyframes scrollTop {
  from{
    transform: translateY(2px);
  }
  to{
    transform: translateY(-1px);
  }
}

.icon-center{
  margin-top: 10px;
  margin-left: 12px;
}

