.toggle-button{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 36px;
  background: transparent;
  border: none;
  margin-left: 6px;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
}

.toggle-button:focus{
    outline: none;
}

.toggle-button__line{
  width: 30px;
  height: 2px;
  background: white;
}
