.content{
  margin-top: 10%;
  color: white;
  font-family: Nunito,sans-serif;
}

.separator-blue{
  background-color: #037fff;
  width: 75px;
  height: 5px;
  float: left;
  border: none;
  margin: 0;
  border-radius: 5px;
}

.container-language{
  width: 40%;
  float: left;
  margin-top: 50px;
  margin-right: 10%;
}

.language-representation{
  width: 100%;
  margin-top: 10px;
}

.language{
  width: 100%;
  font-size: 25px;
  font-weight: lighter;
}

.progressBar{
  width: 85%;
  float: left;
  margin-top: 1.1%;
}

.percentage{
  font-family: Nunito,sans-serif;
  width: 10%;
  margin-right: 5%;
  float: left;
  font-size: 18px;
  color: #a4acc4;
}

.title-section-space-resume{
  color: white;
  font-size: 40px;
  font-family: Nunito,sans-serif;
  margin-bottom: 10px;
  margin-top: 80%;
}

.container-section{
  width: 100%;
  float: left;
}

.container-section-last{
  width: 100%;
  float: left;
  margin-bottom: 5%;
}

.subtitle-resume{
  color: white;
  font-size: 22px;
  font-family: Nunito,sans-serif;
  float: left;
  width: 60%;
  margin-top: 1.45%;
}

.title-resume-icon{
  margin-top: 5%;
  margin-bottom: 10px;
  width: 100%;
  float: left;
}

.icon-resume{
  width: 40px;
  height: auto;
  float: left;
  max-width: 30%;
  border: none;
  margin-right: 1%;
}

.container {
  width: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 2px solid grey;
  margin-top: 4%;
  margin-left: 2%;
  height: 400px;
}

.container div {
  background-color: #ffffff;
  font-weight: bold;
  border: 2px solid grey;
  margin-top: -20px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  margin-left: -63%;
  float: left;
}

.container-line-working-experience{
  width: 100%;
  display: flex;
}

.text-working-experience{
  width: 100%;
  float: left;
  margin-top: 4.75%;
}

.text-working-experience-first{
  width: 100%;
  float: left;
  margin-top: 2%;
}

.text-working-education-first{
  width: 100%;
  float: left;
  margin-top: 2%;
}

.text-working-experience-first-education{
  width: 100%;
  float: left;
  margin-top: 6.5%;
  margin-bottom: 2.3%;
  height: 180px;
}

.text-working-experience-first-education-last{
  width: 100%;
  float: left;
  margin-top: 6.5%;
  margin-bottom: 2.3%;
  height: 140px;
}

.text-date{
  width: 20%;
  float: left;
  margin-top: 0.5%;

}

.container-information-working-experience{
  width: 95%;
  display: flex;
  flex-direction: column;
  margin-top: 0.2%;

}

.text-subtitle-working-experience{
  color: #037fff;
  font-size: 22px;
  font-family: Nunito,sans-serif;
  float: left;
  width: 100%;
  margin-bottom: 1%;
}

.text-info-working-experience{
  color: white;
  font-size: 18px;
  font-family: Nunito,sans-serif;
  float: left;
  width: 100%;
  margin-bottom: 1%;
}

.text-info-explanation-working-experience{
  color: grey;
  font-size: 18px;
  font-family: Nunito,sans-serif;
  float: left;
  width: 100%;
  margin-bottom: 1%;
}

.text-info-explanation-working-experience-big{
  color: grey;
  font-size: 18px;
  font-family: Nunito,sans-serif;
  float: left;
  width: 100%;
  margin-bottom: 1%;
}

@media (min-width: 850px) and (max-width: 1150px) {
  .container-about .container-section{
    margin-bottom: 10%;
    margin-left: 5%;
    width: 95%;
  }
  .language-representation .percentage{
    margin-right: 10%;
  }
  .container-general .title-section-space-resume{
    padding-left: 3%;
    width: 97%;
  }
  .content .container-section{
    margin-left: 3%;
    margin-bottom: 0%;
    width: 97%;
  }
  .content .container-section-last{
    margin-left: 3%;
    width: 97%;
  }
  .title-resume-icon .icon-resume{
    margin-right: 4%;
  }
  .container-section .title-resume-icon{
    margin-bottom: 5%;
    margin-top: 10%;
  }
  .container-section-last .title-resume-icon{
    margin-bottom: 5%;
  }
  .container-line-working-experience .text-working-experience-first{
    margin-left: 5%;
    width: 95%;
    height: 197px;
    margin-bottom: 0%;
    margin-top: 0%;
  }
  .container-line-working-experience .text-working-education-first{
    margin-left: 5%;
    width: 95%;
    height: 197px;
    margin-bottom: 0%;
    margin-top: 1%;
  }
  .container-line-working-experience .text-working-experience-first-education{
    margin-left: 5%;
    width: 95%;
    height: 200px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .container-line-working-experience .text-working-experience-first-education-last{
    margin-left: 5%;
    width: 95%;
    height: 140px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .container-line-working-experience .text-working-experience{
    margin-left: 5%;
    width: 95%;
    height: 190px;
    margin-top: 1.0%;
  }
  .container-information-working-experience .text-info-working-experience{
    margin-bottom: 3%;
    font-size: 16px;
  }
  .container-line-working-experience .text-date{
    margin-right: 3%;
    width: 100%;
  }
  .container-information-working-experience .text-subtitle-working-experience{
    margin-bottom: 3%;
    font-size: 20px;
  }
  .container-information-working-experience .text-info-explanation-working-experience{
    margin-bottom: 3%;
    font-size: 16px;
  }
  .container-information-working-experience .text-info-explanation-working-experience-big{
    margin-bottom: 3%;
    font-size: 16px;
    width: 100%;
  }
  .container div{
    margin-left: -12px;
  }
  .content .container-about{
    margin-bottom: 10%;
  }
}
@media (min-width: 1150px) {
  .container-about .container-section{
    margin-bottom: 12%;
    margin-left: 5%;
    width: 95%;
  }
  .language-representation .percentage{
    margin-right: 10%;
  }
  .container-general .title-section-space-resume{
    padding-left: 3%;
    width: 97%;
  }
  .content .container-section{
    margin-left: 3%;
    width: 97%;
  }
  .content .container-section-last{
    margin-left: 3%;
    width: 97%;
  }
  .title-resume-icon .icon-resume{
    margin-right: 4%;
  }
  .container-section .title-resume-icon{
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .container-section-last .title-resume-icon{
    margin-bottom: 5%;
  }
  .container-line-working-experience .text-working-experience-first{
    margin-left: 5%;
    width: 95%;
    height: 197px;
    margin-bottom: 0%;
    margin-top: 0%;
  }
  .container-line-working-experience .text-working-education-first{
    margin-left: 5%;
    width: 95%;
    height: 197px;
    margin-bottom: 0%;
    margin-top: 1%;
  }
  .container-line-working-experience .text-working-experience-first-education{
    margin-left: 5%;
    width: 95%;
    height: 200px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .container-line-working-experience .text-working-experience-first-education-last{
    margin-left: 5%;
    width: 95%;
    height: 140px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .container-line-working-experience .text-working-experience{
    margin-left: 5%;
    width: 95%;
    height: 190px;
    margin-top: 1.0%;
  }
  .container-information-working-experience .text-info-working-experience{
    margin-bottom: 3%;
    font-size: 16px;
  }
  .container-line-working-experience .text-date{
    margin-right: 3%;
    width: 100%;
  }
  .container-information-working-experience .text-subtitle-working-experience{
    margin-bottom: 3%;
    font-size: 20px;
  }
  .container-information-working-experience .text-info-explanation-working-experience{
    margin-bottom: 3%;
    font-size: 16px;
  }
  .container-information-working-experience .text-info-explanation-working-experience-big{
    margin-bottom: 3%;
    font-size: 16px;
    width: 100%;
  }
  .container div{
    margin-left: -12px;
  }
  .content .separator-blue{
    margin-left: 3%;
  }
  .content .title-section{
    margin-left: 3%;
  }
}

@media (max-width: 850px){
  .container-section .container-language{
    width: 88%;
  }
  .container-about .container-section{
    margin-bottom: 20%;
  }
  .container-general .title-section-space-resume{
    padding-left: 3%;
    width: 97%;
  }
  .content .container-section{
    margin-left: 3%;
    margin-top: 0%;
    width: 97%;
  }
  .content .container-section-last{
    margin-left: 3%;
    width: 97%;
  }
  .title-resume-icon .icon-resume{
    margin-right: 4%;
  }
  .container-section .title-resume-icon{
    margin-bottom: 10%;
    margin-top: 10%;
  }
  .container-section-last .title-resume-icon{
    margin-bottom: 10%;
  }
  .container-line-working-experience .text-working-experience-first{
    margin-left: 5%;
    width: 95%;
    height: 197px;
    margin-bottom: 0%;
    margin-top: -2%;
  }
  .container-line-working-experience .text-working-education-first{
    margin-left: 5%;
    width: 95%;
    height: 197px;
    margin-bottom: 0%;
    margin-top: -2%;
  }
  .container-line-working-experience .text-working-experience-first-education{
    margin-left: 5%;
    width: 95%;
    height: 200px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .container-line-working-experience .text-working-experience-first-education-last{
    margin-left: 5%;
    width: 95%;
    height: 140px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .container-line-working-experience .text-working-experience{
    margin-left: 5%;
    width: 95%;
    height: 200px;
    margin-top: 0;
  }
  .container-information-working-experience .text-info-working-experience{
    margin-bottom: 3%;
    font-size: 16px;
  }
  .container-line-working-experience .text-date{
    margin-right: 3%;
    width: 100%;
  }
  .container-information-working-experience .text-subtitle-working-experience{
    margin-bottom: 3%;
    font-size: 20px;
  }
  .container-information-working-experience .text-info-explanation-working-experience{
    margin-bottom: 3%;
    font-size: 16px;
  }
  .container-information-working-experience .text-info-explanation-working-experience-big{
    margin-bottom: 3%;
    font-size: 85%;
    width: 100%;
  }
  .container div{
    margin-left: -12px;
  }
}
