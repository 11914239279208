.content{
  margin-top: 10%;
  color: white;
}

.container-contact-form{
  width: 50%;
  float: left;
  margin-bottom: 10%;
}

.container-contact-options{
  float: left;
  width: 46%;
  margin-left: 4%;
}

.form-group-name{
  position: absolute;
  left: 15px;
  top: -11px;
  background: #10121b;
  -webkit-transition: all .4s ease-out;
  transition: all .4s ease-out;
  pointer-events: none;
  padding: 0 10px;
  font-size: .94rem;
  width: auto;
}
.form-group-subject{
  position: absolute;
  left: 15px;
  top: -11px;
  background: #10121b;
  -webkit-transition: all .4s ease-out;
  transition: all .4s ease-out;
  pointer-events: none;
  padding: 0 10px;
  font-size: .94rem;
  width: auto;
}
.form-group-email{
  position: absolute;
  left: 15px;
  top: -11px;
  background: #10121b;
  -webkit-transition: all .4s ease-out;
  transition: all .4s ease-out;
  pointer-events: none;
  padding: 0 10px;
  font-size: .94rem;
  width: auto;
}
.form-group-message{
  position: absolute;
  left: 15px;
  top: -11px;
  background: #10121b;
  -webkit-transition: all .4s ease-out;
  transition: all .4s ease-out;
  pointer-events: none;
  padding: 0 10px;
  font-size: .94rem;
  width: auto;
}

.form-group{
  margin-bottom: 3%;
  position: relative;
  width: 100%;
}

.container-contact{
  width: 100%;
  float: left;
  margin-top: 6%;
}

.form-control{
  background-color: transparent;
  border: 1px solid #037fff;
  font-family: Nunito,sans-serif;
  color: #a4acc4;
  height: 50px;
  padding: 0 15px;
  width: 100%;
  box-sizing: border-box;
}

.form-control-message{
  background-color: transparent;
  border: 1px solid #037fff;
  font-family: Nunito,sans-serif;
  color: #a4acc4;
  height: 100px;
  width: 100%;
  padding: 10px 15px;
  box-sizing: border-box;
}

.button-send-mail{
  background-color: #037fff;
  margin-top: 0%;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  float:left;
  margin-right: 5%;
}

.photo-icon-mail{
  width: 50px;
  height: auto;
  float: center;
  max-width: 100%;
  border: none;
  text-align: center;
  vertical-align: middle;
}

.container-contact-email{
  background-color: #191d2b;
  width: 90%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
}

.container-email-border{
  display: inline-block;
  height: 70px;
  width: 70px;
  flex: 0 0 70px;
  max-width: 70px;
  border: 1px solid #2e344e;
  text-align: center;
  line-height: 66px;
  margin-right: 20px;
  vertical-align: middle;
}

.container-email-info{
  width: auto;
  vertical-align: middle;
}

.container-email-title{
  font-family: Nunito,sans-serif;
  color: #fff;
  font-size: 1.14rem;
  line-height: 1.857rem;
  margin-bottom: 11px;
  margin-top: 2px;
}

.container-email-text{
  color: #a4acc4;
  -webkit-transition: all .4s ease-out;
  transition: all .4s ease-out;
}

.container-alert-incorrect{
  background-color: #f63535;
  padding: 3%;
  float: left;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  float:left;
  width: 50%;
}

.container-alert-correct{
  background-color: #3d9f54;
  padding: 3%;
  float: left;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  float:left;
  width: 50%;
}

@media (min-width: 1200px){
  .container-contact-options .container-contact-email{
    width: 95%;
  }
  .container-contact-form .form-group{
    margin-bottom: 10%;
  }
  .container-contact-form .container-alert-incorrect{
    width: 40%;
  }
  .container-contact-form .container-alert-correct{
    width: 40%;
  }
}
@media (min-width: 980px) and (max-width: 1200px) {
  .container-email-info .container-email-text{
    font-size: 1.2vw;
  }
  .container-general .container-contact{
    margin-left: 3%;
    width: 97%;
  }
  .container-contact-options .container-contact-email{
    width: 95%;
  }
  .container-contact-form .form-group{
    margin-bottom: 10%;
  }
  .container-contact-form .container-alert-correct{
    width: 28%;
  }
  .container-contact-form .container-alert-incorrect{
    width: 28%;
  }
}
@media (max-width: 980px) {
  .container-contact .container-contact-form{
    width: 90%;
    margin-left: 4%;
    margin-top: 5%;
  }
  .container-contact .container-contact-options{
    width: 95%;
    margin-bottom: 20%;
  }
  .container-email-info .container-email-text{
    font-size: 13px;
  }
  .container-contact-options .container-contact-email{
    width: 95%;
  }
  .container-contact-form .form-group{
    margin-bottom: 10%;
  }
  .container-contact .button-send-mail{
    margin-bottom: 5%;
  }
}

@media (max-width: 700px) {
  .container-email-info .container-email-text{
    font-size: 12px;
  }
  .container-general .content{
    margin-top: 25%;
  }
}
